@import url(https://fonts.googleapis.com/css?family=Special+Elite);
@import url(https://fonts.googleapis.com/css?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Lora);
@import url(https://fonts.googleapis.com/css?family=Archivo);
/* Tove mars 2021 */
/* Johannamuseets röda färg: #a81013*/
/* Typsnitt */
h1, h2, h3, h4{font-family: 'Special Elite'; font-weight:bold;} /* Lora */
body{font-family:Lora;} /* Was Archivo */
p{font-size:130%}
a{color:#a81013;}

/* Allmänt body */
body{
    margin: 0;
    height: 100vh;
    width: 100vw;
    background-color: whitesmoke;
    padding-top: 130px;
    
    background-repeat:repeat-y; 
    background-image:url('http://app.johannamuseet.se/bilder/bg_rad5.jpg');
    }

.darkbg{background-color: #000;}
.lightbg{background-color:#f5f5f5;}

.hide{display:none;}

/* Översta toppen */
.top{background-color: white; margin-top:0px !important;height:40px;}
.logo{margin:10px 0 0 30px;width:90px; height:31px; border:none; background-size: 90px 31px; background-repeat:no-repeat; background-image:url('http://app.johannamuseet.se/bilder/johanna_logo.png')}/*72px 25px*/
.knapprad{height:90px; 
    text-align:center !important;
    background-image:url('http://app.johannamuseet.se/bilder/bg_topp.jpg');
    background-position: bottom left;
}
.knappradspos{margin-left:auto; margin-right:auto;width:80%;height:90px;}
.ruta{ /* Nummerrutan */
    bottom-padding:none !important;
    vertical-align: middle;
    text-align:center;
    width:150px; 
    float:left; 
    /* Safari 3-4, iOS 1-3.2, Android 1.6- */
    /* Firefox 1-3.6  */
    /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */    border-radius: 10px;
    height:45px;
    font-size:180%;
    font-family: 'Lora', Tahoma, Trebuchet;
    font-weight:700;
    color:#000;
    background-color: #fff;
    margin-top:19px;
}
/* Placeholder text i sifferruta: */
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #c0c0c0 !important;
    bottom-padding:none !important;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: #c0c0c0 !important;
    bottom-padding:none !important;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: #c0c0c0 !important;
    bottom-padding:none !important;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: #c0c0c0 !important;
    bottom-padding:none !important;
  }
  input:focus {
    background-color: #fff !important;
  }

.skickaknapp {
    height:45px;
    width: 45px;
    float:left;
    margin:20px 0 0 20px;
    font-family: 'Lora', Tahoma, Trebuchet;
    color: #fff !important;
    font-weight:700;
    text-align: center;
    text-decoration: none;
    font-size: 25px;
    cursor: pointer;
    background-color: #a81013  !important;   
    border-radius: 50%;
    background-image: url('http://app.johannamuseet.se/bilder/pil.png');
    background-repeat: no-repeat;
    background-color: transparent;
    background-size: 25px 25px;
    background-position: center;
    border:3px solid #fff;
  }
 

/* tyvärr - meddelandet */
.tyvarr{width:60%; margin:30px auto 0px auto; border:none !important}

/* hand 1 */
.hand2{
height:70px;
width: 200px;
background-image: url('http://app.johannamuseet.se/bilder/hand2.png');
background-repeat: no-repeat;
background-color: transparent;
background-size: 100% 70px;
}

/* Välkomstsidan */
.apptitel{margin-top:20px !important;}
.apptitel a{text-decoration:none;}
.framsida_text{ margin: 30px; font-family: 'Lora', Tahoma, Trebuchet;}
.framsida_text h3{ margin-bottom:20px;}
.framsida{background-color: transparent; min-height:100% !important; height: 90vh !important;}

/* Språkväljare */
.langpick{height:30px; width:140px; position: absolute;top:16px; right:30px;}
@-moz-document url-prefix() {
    .langpick {
      top: 10px;
    }
  }
.langpick img{width:40px; height:auto;margin-left:5px;}/*transform: rotate(-25deg);*/
.langpickSE{width:40px; height:25px; margin-left:5px; border:none; background-size: 40px 25px; background-repeat:no-repeat; background-image:url('http://app.johannamuseet.se/bilder/icon_SE.gif')}
.langpickEN{width:40px; height:25px; margin-left:5px; border:none; background-size: 40px 25px; background-repeat:no-repeat; background-image:url('http://app.johannamuseet.se/bilder/icon_EN.gif')}
.langpickDE{width:40px; height:25px; margin-left:5px; border:none; background-size: 40px 25px; background-repeat:no-repeat; background-image:url('http://app.johannamuseet.se/bilder/icon_DE.gif')}


/* Textsjoket */
.infochunk{
    border-top:1px solid #000;
    background-color:transparent;
    margin-top:-20px; 
    height: 100% !important;
}
.textDiv{
    margin:20px 6% 40px 6%;
    height:100% !important;
}
.textDiv h3{
    margin-bottom:30px;
}
.smallp{color:#a81013;; margin-bottom:5px;font-size:100%; font-weight:700;}

/* Video */
  .player-wrapper {
    position: relative;
    padding-top: 56.25%;
    margin-bottom:30px;
 }
 .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }
  .react-player video {
    position: relative;
    left: 0;
    top: 0;
    -webkit-transform: none;
            transform: none;
  }
  .react-player > div {
    position: absolute;
    height:100% !important;
    width:100% !important;
  }

/* Responsive Carousel */
.control-dots{margin:0px !important; bottom: 5% !important;}
.carousel .control-dots .dot {opacity:1 !important; box-shadow: none !important; width: 15px !important; height: 15px !important; background: #a81013 !important; background-color:#a81013 !important;}
.carousel .control-dots .dot.selected{background: #000000 !important; background-color:#000000 !important;}
.carousel.carousel-slider{margin-bottom:20px;}
.animated{text-align:center;margin-bottom:0px;}
.thumb.selected{ border: 3px solid #ccc !important;}
.carousel-status{display:none;}

/*Bootstrap override */
.col-md-12{padding:0;
    width: 100%;
}

/* Footer */
.footer{
    margin-top: 1rem;
    padding: 1rem;
    background-color: #a81013;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height:40px;
    background-image:url('http://app.johannamuseet.se/bilder/bg_botten.jpg');
    z-index: 999;
  }
.footer span{
   font-family:'Material Icons';
   float: right;
   margin-top:-7px;
}
.material-icons.md-36 { font-size: 30px; }


@media (min-width:575px) {
    .infochunk{
        border-top:none;
    }
    .slider-wrapper img{border:5px solid #fff !important; margin-top:10px;} /* bilden i carouosel */
}

@media (max-width:1099px) {
    .messageifbigscreen{
        display:none;
    }
}
@media (min-width:1100px) {
.hideifbigscreen{display:none}
.messageifbigscreen{display:block;margin-top: 30px;}
body{background-image:none; background-color:#fff !important;}
.infochunk{
    background-color:#fff !important;
}
}

